<template>
  <div>
    <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
      <div>
        <md-field style="width: 400px; max-width: 70vw" >
          <label for="role">{{selectedMessageType ? 'Тип сообщений' : 'Тип сообщений'}}</label>
          <md-select v-model="selectedMessageType" name="value" id="message_type">
            <md-option  v-for="(name, value) in types" :key="value" :value="value">{{name}}</md-option>
          </md-select>
        </md-field>
      </div>
      <!--<div>Сообщения</div>-->
      <div>Дата  <input
            v-model="selectedDate"
            type="datetime-local"
            id="meeting-time"
            name="meeting-time"
            min="2024-01-01T00:00" /></div>
    </div>
    <div class="message-container">
      <md-dialog :md-active.sync="showViewMessageForm"
                 :before-close="() => showViewMessageForm = false"
      >
        <md-dialog-content style="padding: 10px">
          <!--<md-dialog-actions>-->
            <!--<md-button class="md-primary" @click="showViewMessageForm = false">Закрыть</md-button>-->
          <!--</md-dialog-actions>-->
          <view-message-form :message="selectedMessage" @closeViewMessageForm="handleCloseViewMessageForm"/>
        </md-dialog-content>
      </md-dialog>

      <table>
        <thead>
        <tr>
          <th>Дата</th>
          <th>Вреия</th>
          <th>Отправитель</th>
          <th>Заголовок</th>
          <th style="width: 10px"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="message in messages" :key="message.id" :class="{'selected': selectedMessage && message.id === selectedMessage.id, 'viewed' : message.viewed && !(selectedMessage && message.id === selectedMessage.id)}" @click="openMessage(message)">
          <td>{{getDateH(message)}}</td>
          <td>{{getTimeH(message)}}</td>
          <td>{{message.sender}}</td>
          <td>{{message.address}} </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import {
    getActualMessages,
    MESSAGE_TYPE,
    MESSAGE_TYPE_TITLES,
    MESSAGE_COLUMNS,
    markMessageViewed
  } from "../api/message-controller";
  import {getUtcDateFromInput} from "../utils/time";
  import {getFormattedDate} from "../utils/timezone";
  import {getTime, getDate} from "../utils/time"
  import ViewMessageForm from "./base/ViewMessageForm"
  import {SoundCall} from "../utils/sound/soundCall";
  import {mapGetters} from "vuex";
  // let signalInterval = null

  const sound = new SoundCall()

  let messageInterval = null
  const MESSAGE_INTERVAL_MS = 15000

  export default {
    name: 'messages',
    components: {
      ViewMessageForm
    },
    data() {
      return {
        types: MESSAGE_TYPE_TITLES,
        columns: MESSAGE_COLUMNS,
        selectedMessageType: MESSAGE_TYPE.INPUT_MESSAGE,
        selectedDate: getUtcDateFromInput(getFormattedDate('UTC', new Date()).replaceAll(", ", "T")),
        messages: [],
        selectedMessage: null,
        showViewMessageForm: false,
        lastMessageDate: null,
        inMessags: [],
        inConfirmations: [],
        outMessages: [],
        outConfirmations: []

      }
    },
    watch: {
      selectedMessageType(newVal) {
        if (newVal) {
          this.updateMessages(newVal)
        }
      }
    },
    computed: {
      ...mapGetters(['getSoundOffStatus'])
    },
    methods: {
      openMessage(message) {
        this.selectedMessage = message;
        this.showViewMessageForm = true
        markMessageViewed(message.id)
          .then(() => {
            message.viewed = true
          })
      },
      getDateH(message) {
        if (this.selectedMessageType === MESSAGE_TYPE.INPUT_MESSAGE) {
          return getDate(message.received)
        } else {
          return getDate(message.sent)
        }
      },
      getTimeH(message) {
        if (this.selectedMessageType === MESSAGE_TYPE.INPUT_MESSAGE) {
          return getTime(message.received)
        } else {
          return getTime(message.sent)
        }
      },
      async getActualMessages(type) {
        let results = await getActualMessages(type, this.selectedDate)
        let messages = results ? results : []
        this.updateLastDate(messages)
        // console.log('this.getSoundOffStatus2', this.getSoundOffStatus)
        // if (!this.getSoundOffStatus) sound.checkAndPlayMessage(this.messages)
        // return results.payload ? results.payload : []
        return results ? results : []
      },
      updateLastDate(messages) {
        let lastMessage = messages && messages.length ? messages[messages.length - 1] : null
        if (!lastMessage) return;
        switch (lastMessage.type) {
          case MESSAGE_TYPE.INPUT_MESSAGE: this.lastMessageDate = lastMessage.received; break
          case MESSAGE_TYPE.OUTPUT_MESSAGE: this.lastMessageDate = lastMessage.sent; break;
          default:
            this.lastMessageDate = null
        }
      },
      async updateMessages(type, date) {
        let inMessages = await this.getActualMessages(MESSAGE_TYPE.INPUT_MESSAGE, date)
        let inConfirmations = await this.getActualMessages(MESSAGE_TYPE.INPUT_CONFIRMATION, date)
        let outMessages = await this.getActualMessages(MESSAGE_TYPE.OUTPUT_MESSAGE, date)
        let outConfirmations = await this.getActualMessages(MESSAGE_TYPE.OUTPUT_CONFIRMATION, date)
        switch (type) {
          case MESSAGE_TYPE.INPUT_MESSAGE:
            this.messages = inMessages;
            break;
          case MESSAGE_TYPE.INPUT_CONFIRMATION:
            this.messages = inConfirmations
            break;
          case MESSAGE_TYPE.OUTPUT_MESSAGE:
            this.messages = outMessages;
            break;
          case MESSAGE_TYPE.OUTPUT_CONFIRMATION:
            this.messages = outConfirmations;
            break;
          default:
            this.messages = []
        }
        this.updateLastDate(this.messages)
        if (inMessages.some(m => {
          console.log(!m.viewed)
          return !m.viewed
        })) {
          if (!this.getSoundOffStatus) {
            console.log('play message', new Date())
            sound.checkAndPlayMessage(inMessages)
          }
        }
      },
      getTitle(key) {
        return MESSAGE_TYPE_TITLES[key]
      },
      handleCloseViewMessageForm() {
        this.showViewMessageForm = false
      }
    },
    async mounted() {
      await this.getActualMessages(this.selectedMessageType)
      let that = this
      if (messageInterval) {
        clearInterval(messageInterval)
      }
      that.updateMessages(that.selectedMessageType, that.lastMessageDate)
      messageInterval = setInterval(() => {
        that.updateMessages(that.selectedMessageType, that.lastMessageDate)
      }, MESSAGE_INTERVAL_MS)

    },
    destroyed() {
      if (messageInterval) {
        clearInterval(messageInterval)
      }
    }
  }
</script>

<style scoped>
  @media (min-width: 599px){

    .message-container {
      max-height: calc(46vh - 40px);
      overflow-y: auto;
      min-height: 300px;
    }

    table tbody {
      display: block;
      max-height: calc(55vh - 200px);
      overflow-y: scroll;
    }

    table thead, table tfoot, table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    td {
      border: 1px solid rgba(0,0,0,0.5);
      line-height: 20px;
      font-size: 14px;
      padding: 2px;
    }

  }

  @media (max-width: 600px){
    th {
      padding: 2px;
    }
    td {
      padding: 4px;
      border: 1px solid rgba(0,0,0,0.5);
      line-height: 24px;
      font-size: 15px;
    }
  }

  tr.selected {
    background-color: #9acfea;
  }

  tr.viewed {
    background-color: lightgray;
  }

</style>
