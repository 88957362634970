<template>
  <div :style="getStyle()"></div>
</template>

<script>
  export default {
    name: 'ColoredLine',
    props: {
      color: String,
      width: {
        type: Number,
        default: 2
      }
    },
    methods: {
      getStyle() {
        return `width: ${this.width}px; border: 1px solid black; background-color: ${this.color}; `
        // return `width: ${this.width}px; border: 1px solid black; background-color: grey; `
      }
    }
  }
</script>